import { Block, Button } from "@/components";
import CategoryItem from "./CategoryItem";
import polyglot from "@/utils/polyglot";
import { BUTTON } from "@/components/Styles/variants";
import { spacings } from "@/assets/themes";
import { useAskService } from "@/modules/hooks";

const CategoriesVertical = ({ block }) => {
  const askService = useAskService();

  const calculateCols = (length) => {
    if (length <= 2) {
      return { xs: 1, lg: 2, xl: 2 };
    }
    return { xs: 1, md: 2, xl: 3 };
  };

  return (
    <Block spaceY={spacings.s}>
      <Block
        display="grid"
        gridCols={calculateCols(block?.data.length)}
        gap={{ xs: spacings.s, md: spacings.m }}
      >
        {block?.data?.map((cat, i) => (
          <CategoryItem
            id={cat}
            name={cat.name}
            src={cat.src}
            category_id={cat.category_id}
            url_params={cat.url_params}
            size="large"
            key={`categories-vertical-${block.category_id}-${i}`}
          />
        ))}
      </Block>
      {block.category_id && (
        <Button.Small
          kind={BUTTON.KIND.SECONDARY}
          onClick={() => askService.show({ id: block.category_id })}
        >
          {polyglot.t("common.see_more")}
        </Button.Small>
      )}
    </Block>
  );
};

export default CategoriesVertical;
