import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import BaseApp from "@/modules/BaseApp";
import GiftCardNewForm from "../pages/GiftCard/GiftCardNew/GiftCardNewForm";
import store from "../store";

const App = (props, rails) => () =>
  (
    <BaseApp>
      <Router>
        <Route
          render={(location) => (
            <Provider store={store}>
              <Switch>
                <Route
                  path="/gift_cards/wizard"
                  render={() => (
                    <GiftCardNewForm {...props} location={location} />
                  )}
                />
              </Switch>
            </Provider>
          )}
        />
      </Router>
    </BaseApp>
  );

export default App;
