import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFetch, useToast } from "../hooks";
import { api, browserInfo } from "@/utils";
import { ACCOUNT_TAXES_PATH } from "@/pages/dashboard/config/routes";
import polyglot from "@/utils/polyglot";

export const useGetTax = ({ id }) => useFetch(`/account/taxes/${id}`);
export const usePutTax = ({ id }) => {
  const toast = useToast();
  const reactQuery = useQueryClient();
  return useMutation(
    (values) =>
      api.put(`/account/taxes/${id}/pay`, {
        browserInfo,
        ...values,
      }),
    {
      onSuccess: ({ data }) => {
        if (data.data.secure_url) window.location.href = data.data.secure_url;
        const query = reactQuery.getQueryState([ACCOUNT_TAXES_PATH]);
        if (query) {
          reactQuery.refetchQueries([ACCOUNT_TAXES_PATH]);
        }
      },
      onError: (err) => {
        if (err.response.status === 403) {
          window.location.href = err.response.data?.redirect_url_on_error;
        } else {
          toast.danger(err.response.data.error_message);
        }
      },
    }
  );
};
