import { spacings } from "@/assets/themes";
import { Banner, Block, Carousel } from "@/components";
import { BANNER } from "@/components/Styles/variants";
import {
  useBanners,
  useBreakpoints,
  useGoogleAnalytics,
} from "@/modules/hooks";
import { getHomeBannerPressEvent } from "@/utils/analytics-events";

const HomeEventBanner = ({ event_banners }) => {
  const { banners } = useBanners();
  const breakpoints = useBreakpoints();
  const { sendEvent } = useGoogleAnalytics();
  const hasBanners = event_banners.filter((b) => !!banners[b])?.length > 0;
  if (!hasBanners) return null;

  return (
    <Block
      marginX={{ xs: `calc(${spacings.m} * -1)`, md: "0px" }}
      paddingTop={spacings.m}
    >
      <Carousel
        slideToShow={1}
        gutter={breakpoints.get({ xs: spacings.s, md: spacings.m })}
        paddingX={{ xs: spacings.m, md: "0px" }}
        withGutters={breakpoints.get({ xs: true, sm: false })}
      >
        {event_banners
          .filter((b) => !!banners[b])
          .map((key) => (
            <Banner
              {...banners[key]}
              size={breakpoints.get({
                xs: BANNER.SIZE.MEDIUM,
                xl: BANNER.SIZE.LARGE,
              })}
              onClick={(e) => {
                if (banners[key]?.event)
                  sendEvent(getHomeBannerPressEvent(banners[key]));
                if (banners[key]?.onClick) banners[key].onClick(e);
              }}
              onClose={
                banners[key]?.closable ? () => banners[key].hide() : undefined
              }
              key={`home-banner-${key}`}
            />
          ))}
      </Carousel>
    </Block>
  );
};

export default HomeEventBanner;
