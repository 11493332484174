import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const HOMEWORK_PRICE_PER_HOUR = {
  fr: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  be: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  ch: {
    0: 30,
    1: 25,
    2: 20,
    3: 20,
  },
  nl: {
    0: 22,
    2: 20,
    3: 18,
  },
  lu: {
    0: 29,
    2: 26,
    3: 23,
  },
  uk: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  ie: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  es: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  pt: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  },
  it: {
    0: 22,
    1: 20,
    2: 18,
    3: 16,
  }
};

const HOMEWORK_DEGREE = {
  fr: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  be: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  ch: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  nl: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  lu: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  uk: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  ie: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  es: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 1, label: polyglot.t("categories.high_school"), duration: 1.5 },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
  pt: [
    {
      value: 0,
      label: polyglot.t("categories.higher_education"),
      duration: 1.5,
    },
    { value: 2, label: polyglot.t("categories.middle_school"), duration: 1 },
    { value: 3, label: polyglot.t("categories.primary_school"), duration: 1 },
  ],
};

const config = { withFrequency: true };

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
  nh_specific3: yup.number().required(),
});

export function useCategory29() {
  const initialValues = {
    options: [],
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.education_level_needed"),
      options: HOMEWORK_DEGREE[polyglot.country],
    },
    nh_specific3: {
      label: polyglot.t("categories.course_format_needed"),
      options: [
        { value: 0.0, label: polyglot.t("categories.at_home") },
        { value: 1.0, label: polyglot.t("categories.online") },
      ],
    },
  };

  const getNbHours = (values) =>
    nhConfig.nh_specific.options.find(
      (option) => option.value === values.nh_specific
    )?.duration;

  const getPricePerHour = (values) =>
    HOMEWORK_PRICE_PER_HOUR[polyglot.country][values.nh_specific];

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl label={nhConfig.nh_specific.label}>
            <FormSelectables
              options={nhConfig.nh_specific.options}
              isWrap
              name="nh_specific"
            />
          </StyledFormControl>
          <StyledFormControl label={nhConfig.nh_specific3.label}>
            <FormSelectables
              options={nhConfig.nh_specific3.options}
              name="nh_specific3"
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    getPricePerHour,
    config,
  };
}
