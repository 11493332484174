import React, { Component, Fragment } from "react";
import { Field, Formik, withFormik } from "formik";
import { Link, withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";
import GiftCard from "../../../../components/GiftCard";
import WizardNavigation from "./WizardNavigation";
import WizardInput from "../../../../components/Wizard/WizardInput";
import polyglot from "../../../../utils/polyglot";
import GifCardAmount from "./GiftCardSetup/GiftCardAmount";

class GiftCardSetup extends Component {
  state = {
    name: "",
    amount: "",
    currIndex: 0,
    triggerNext: false,
  };

  fillInput = (object, value) => {
    this.setState({ [value]: object[value] });
    this.props.setFieldTouched(value, true);
  };

  initStep = () => {
    const { initialValues, values } = this.props;
    if (initialValues && !values.name && !values.amount) {
      Object.keys(initialValues).map((value, index) => {
        initialValues[value].toString().length > 0
          ? this.fillInput(initialValues, value)
          : false;
      });
    } else {
      Object.keys(values).map((value, index) => {
        values[value].toString().length > 0
          ? this.fillInput(values, value)
          : false;
      });
    }
  };

  componentDidMount() {
    this.initStep();
  }

  handleInputChange = (event, customInputName = false) => {
    if (customInputName === "amount") {
      this.props.setFieldValue("amount", Number(event.target.value));
    }
    let { name } = event.target;
    customInputName ? (name = customInputName) : null;
    this.setState({
      [name]: event.target.value,
    });
  };

  stepIsValid = () => {
    const { errors, isValid, values, touched } = this.props;
    const emptyAndErrors =
      (touched.name > 0 && errors.name) ||
      (touched.amount > 0 && errors.amount);
    const empty = values.name.length === 0 || values.amount.length === 0;
    return !(emptyAndErrors || empty);
  };

  radioCustomIsSelected = () => {
    const { values } = this.props;
    return !!values.customAmount;
  };

  nextOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("btn-wizard-next").click();
    }
  };

  render() {
    const {
      errors,
      isValid,
      touched,
      values,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      currency,
    } = this.props;
    return (
      <div>
        <div className="gift-card-wizard-content">
          <h2 className="gift-card-wizard-content-title">
            {polyglot.t("gift_cards.new.title")}
          </h2>
          <div>
            <GiftCard
              name={this.state.name}
              currency={currency}
              amount={this.state.amount}
            />
            <div className="mt-4">
              <WizardInput
                {...this.props}
                label={polyglot.t("gift_cards.new.name.label")}
                type="text"
                name="name"
                className="py-3"
                maxLength="20"
                autoFocus
                tabIndex={100}
                placeholder={polyglot.t("gift_cards.new.name.placeholder")}
                onChange={(event) => {
                  this.props.handleChange(event);
                  this.handleInputChange(event);
                }}
              />
              <GifCardAmount
                {...this.props}
                nextOnEnter={this.nextOnEnter}
                handleInputChange={this.handleInputChange}
                radioCustomIsSelected={this.radioCustomIsSelected}
              />
            </div>
          </div>
        </div>
        <WizardNavigation
          backUrl={this.props.backUrl}
          currStep={this.state.currIndex}
          stepIsValid={this.stepIsValid()}
        />
      </div>
    );
  }
}
export default withRouter(GiftCardSetup);
