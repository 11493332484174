import React, { Component } from "react";
import {
  Switch,
  Route,
  Prompt,
  Redirect,
  matchPath,
  withRouter,
} from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import GiftCardPaiment from "./GiftCardNewForm/GiftCardPaiment";
import GiftCardSetup from "./GiftCardNewForm/GiftCardSetup";
import WizardHeader from "../../../components/Wizard/WizardHeader";
import { submitForm } from "../../../modules/actions/global/form-actions";
import WizardAlert from "../../../components/Wizard/WizardAlert";
import polyglot from "../../../utils/polyglot";
import { scrollToTop } from "../../../utils/functions";
import { updateStep } from "../../../modules/actions/wizard-actions";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(polyglot.t("common.errors.field_required")),
  customAmount: Yup.boolean().notRequired(),
  amount: Yup.number().when("customAmount", {
    is: true,
    then: Yup.number()
      .min(
        10,
        polyglot.t("common.errors.amount_field_cant_be_less_than", {
          amount: 10,
        })
      )
      .max(
        1000,
        polyglot.t("common.errors.amount_field_cant_be_more_than", {
          amount: 1000,
        })
      )
      .required(polyglot.t("common.errors.amount_required")),
  }),
  useNewCard: Yup.boolean().required(),
  cardNumber: Yup.string().when("useNewCard", {
    is: true,
    then: Yup.string()
      .max(19)
      .required(polyglot.t("common.errors.field_required")),
  }),
  cardCvx: Yup.number().when("useNewCard", {
    is: true,
    then: Yup.number()
      .min(
        3,
        polyglot.t("common.errors.number_field_cant_be_less_than", {
          count: 3,
        })
      )
      .required(polyglot.t("common.errors.field_required")),
  }),
  cardExpirationDate: Yup.string().when("useNewCard", {
    is: true,
    then: Yup.string().required(polyglot.t("common.errors.field_required")),
  }),
});

class GiftCardNewForm extends Component {
  state = {
    submitted: false,
    stepIndex: 0,
    alert: {},
    mangopayConfig: null,
    creditCard: null,
  };

  componentDidMount() {
    const { alert, mangopayConfig, creditCard } = this.props;
    alert ? this.setState({ alert: { text: alert, type: "danger" } }) : null;
    mangopayConfig ? this.setState({ mangopayConfig }) : null;
    creditCard ? this.setState({ creditCard }) : null;
  }

  componentDidUpdate(prevProps) {
    const { form } = this.props;
    form.succeed !== prevProps.form.succeed ? this.handleSubmitSucceed() : null;
    form.error !== prevProps.form.error ? this.handleSubmitErrors() : null;
  }

  static getDerivedStateFromProps = (nextProps) => {
    const { wizard } = nextProps;
    return { stepIndex: wizard.step - 1 };
  };

  updateStepIndex = (value) => {
    this.setState({ stepIndex: value - 1 });
  };

  resetAlert = () => {
    const elem = document.querySelectorAll(".wizard-alert")[0];
    if (elem) {
      elem.style.WebKitAnimation = "none";
      elem.style.animation = "none";
      setTimeout(() => {
        elem.style.WebKitAnimation = "";
        elem.style.animation = "";
      }, 100);
    }
  };

  handleSubmitCallback = () => {
    scrollToTop();
    this.setState({ submitted: false });
  };

  handleSubmitSucceed = () => {
    const { form } = this.props;
    form.succeed.redirectUrl
      ? (window.location = form.succeed.redirectUrl)
      : false;
  };

  handleSubmitErrors = () => {
    const { form } = this.props;
    const { alert } = this.state;
    this.handleSubmitCallback();
    form.error.errorMessage
      ? (this.setState({
          alert: {
            text: form.error.errorMessage,
            type: "danger",
          },
        }),
        alert.text ? this.resetAlert() : null)
      : null;
    const { error } = form;

    error.mangopayConfig
      ? this.setState({ mangopayConfig: error.mangopayConfig })
      : null;
    error.creditCard ? this.setState({ creditCard: error.creditCard }) : null;
  };

  handleSubmit = (data) => {
    const { mangopayConfig } = this.state;
    const { config, submitForm } = this.props;
    this.setState({ alert: {}, submitted: true });
    const mergeConfig = { ...mangopayConfig, ...config };
    let filteredData = data;
    if (data.useNewCard === false) {
      filteredData = _.omit(data, [
        "customAmountInput",
        "customAmount",
        "useNewCard",
        "cardNumber",
        "cardCvx",
        "cardExpirationDate",
      ]);
    } else {
      filteredData = _.omit(data, [
        "customAmountInput",
        "customAmount",
        "useNewCard",
      ]);
    }
    submitForm(filteredData, mergeConfig, "post");
  };

  displayGiftCardProps = (value) => {
    const { giftCard } = this.props;
    return giftCard[value] ? giftCard[value] : "";
  };

  render() {
    const { giftCard, location, currency, backUrl, updateStep } = this.props;
    const { creditCard, submitted, stepIndex, alert } = this.state;
    return (
      <div className="gift-card-wizard">
        <Formik
          initialValues={{
            name: `${this.displayGiftCardProps("name")}`,
            amount: `${this.displayGiftCardProps("amount")}`,
            customAmount: false,
            useNewCard: true,
            cardCvx: "",
            cardNumber: "",
            cardExpirationDate: "",
          }}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <Form>
              <WizardHeader
                activeIndex={submitted ? stepIndex + 1 : stepIndex}
                steps={[
                  { text: polyglot.t("gift_cards.wizard.steps.step_1") },
                  { text: polyglot.t("gift_cards.wizard.steps.step_2") },
                ]}
              />
              <div className="wizard-container">
                <WizardAlert text={alert.text} type={alert.type} />
              </div>
              <TransitionGroup style={{ position: "relative" }}>
                <CSSTransition
                  key={location.key}
                  classNames="wizard-transition"
                  timeout={400}
                >
                  <Switch location={location}>
                    {giftCard.name && giftCard.amount ? (
                      <Redirect
                        from="/gift_cards/wizard"
                        exact
                        to="/gift_cards/wizard/step-2"
                      />
                    ) : (
                      <Redirect
                        from="/gift_cards/wizard"
                        exact
                        to="/gift_cards/wizard/step-1"
                      />
                    )}
                    <Route
                      exact
                      path="/gift_cards/wizard/step-1"
                      render={() => (
                        <GiftCardSetup
                          currency={currency}
                          backUrl={backUrl}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/gift_cards/wizard/step-2"
                      render={() => (
                        <GiftCardPaiment
                          backUrl={backUrl}
                          submitted={submitted}
                          creditCard={creditCard || null}
                          updateStep={updateStep}
                          {...props}
                        />
                      )}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ submitForm, updateStep }, dispatch),
});

const mapStateToProps = (state) => ({
  form: state.form,
  wizard: state.wizard,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GiftCardNewForm)
);
