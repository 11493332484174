import { colors, sizes, spacings } from "../../assets/themes";
import { useBreakpoints } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import UserPreview from "../../pages-admin/UserPreview/UserPreview";
import { JOBBER } from "../../pages-admin/UserPreview/config";
import {
  CREDIT_TAG,
  ECO_DISTANCE_LEVEL_1_TAG,
  ECO_DISTANCE_LEVEL_2_TAG,
  ECO_DISTANCE_LEVEL_3_TAG,
  GREAT_VALUE_LEVEL_1_TAG,
  GREAT_VALUE_LEVEL_2_TAG,
  GREAT_VALUE_LEVEL_3_TAG,
  HIGH_DEMAND_TAG,
  OFFER_STATE_CANCELED,
  OFFER_STATE_PAID,
  OFFER_STATE_PENDING,
  OFFER_STATE_SELECTED,
} from "../../pages/Job/utils";
import polyglot from "../../utils/polyglot";
import Alert from "../Alert";
import Block from "../Block";
import Icon from "../Icon";
import Link from "../Link";
import Rating from "../Rating";
import StatusEnhancer from "../StatusEnhancer";
import { ALERT, TAG } from "../Styles/variants";
import Tag from "../Tag";
import { H4 } from "../Text";
import Avatar from "../avatar";
import EcoDistanceTag from "./EcoDistanceTag";
import HighDemandTag from "./HighDemandTag";
import JobChat from "./JobChat";
import NewJobberCreditTag from "./NewJobberCreditTag";
import OfferBabySitting from "./OfferBabySitting";
import OfferGreatValueTag from "./OfferGreatValueTag";
import OfferJobberRating from "./OfferJobberRating";
import OfferLastRate from "./OfferLastRate";
import OfferPhoneChat from "./OfferPhoneChat";
import OfferSkills from "./OfferSkills";
import OfferTransactionDetails from "./OfferTransactionDetails";
import OfferWrapper from "./OfferWrapper";
import PendingActions from "./PendingActions";
import SelectedActions from "./SelectedActions";
import TopJobberTag from "./TopJobberTag";
import Trucks from "./Trucks";

const MOBILE_AVATAR_HEIGHT = sizes.size72;

const OfferTags = ({ offer }) => {
  const { isPoster } = useJob();
  if (
    !isPoster ||
    (offer.state === OFFER_STATE_PENDING &&
      (offer.offer_badges.length > 0 || offer.jobber.is_top_jobber))
  ) {
    return (
      <Block marginTop={spacings.xs}>
        {offer.offer_badges?.includes(CREDIT_TAG) && (
          <Block marginBottom={spacings.xs}>
            <NewJobberCreditTag first_name={offer.jobber.first_name} />
          </Block>
        )}
        {offer.jobber.is_top_jobber && (
          <Block marginBottom={spacings.xs}>
            <TopJobberTag />
          </Block>
        )}
        {offer.offer_badges?.includes(ECO_DISTANCE_LEVEL_1_TAG) && (
          <Block marginBottom={spacings.xs}>
            <EcoDistanceTag level={1} />
          </Block>
        )}
        {offer.offer_badges?.includes(ECO_DISTANCE_LEVEL_2_TAG) && (
          <Block marginBottom={spacings.xs}>
            <EcoDistanceTag level={2} />
          </Block>
        )}
        {offer.offer_badges?.includes(ECO_DISTANCE_LEVEL_3_TAG) && (
          <Block marginBottom={spacings.xs}>
            <EcoDistanceTag level={3} />
          </Block>
        )}
        {offer.offer_badges?.includes(GREAT_VALUE_LEVEL_1_TAG) && (
          <Block marginBottom={spacings.xs}>
            <OfferGreatValueTag level={1} />
          </Block>
        )}
        {offer.offer_badges?.includes(GREAT_VALUE_LEVEL_2_TAG) && (
          <Block marginBottom={spacings.xs}>
            <OfferGreatValueTag level={2} />
          </Block>
        )}
        {offer.offer_badges?.includes(GREAT_VALUE_LEVEL_3_TAG) && (
          <Block marginBottom={spacings.xs}>
            <OfferGreatValueTag level={3} />
          </Block>
        )}
        {offer.offer_badges?.includes(HIGH_DEMAND_TAG) && (
          <Block marginBottom={spacings.xs}>
            <HighDemandTag />
          </Block>
        )}
      </Block>
    );
  }
  return null;
};

const StateSpecific = ({ offer }) => {
  const { isPoster } = useJob();

  if (isPoster) {
    if (offer.state === OFFER_STATE_SELECTED) {
      return (
        <Block>
          <Block marginBottom={spacings.s}>
            <JobChat offer={offer} />
          </Block>
          {offer.last_instant_payment && (
            <Block marginBottom={spacings.s}>
              <OfferTransactionDetails offer={offer} />
            </Block>
          )}
          {(offer.rateable || offer.rate) && (
            <Block marginBottom={spacings.s}>
              <OfferJobberRating
                id={offer.id}
                rate={offer.rate}
                openInModal
                rateable={offer.rateable}
                jobber={offer.jobber}
              />
            </Block>
          )}
          <Block marginBottom={spacings.s}>
            <SelectedActions offer={offer} />
          </Block>
        </Block>
      );
    }
    if (
      offer.state === OFFER_STATE_PAID ||
      offer.state === OFFER_STATE_CANCELED
    ) {
      return (
        <>
          <Block marginBottom={spacings.s}>
            <OfferTransactionDetails offer={offer} />
          </Block>
          {(offer.rateable || offer.rate) && (
            <Block marginBottom={spacings.s}>
              <OfferJobberRating
                id={offer.id}
                rate={offer.rate}
                openInModal
                rateable={offer.rateable}
                jobber={offer.jobber}
              />
            </Block>
          )}
        </>
      );
    }
  }
  if (!isPoster || offer.state === OFFER_STATE_PENDING) {
    return (
      <>
        {(offer.jobber.license_number ||
          offer.jobber.toddlers ||
          offer.jobber.preschoolers ||
          offer.jobber.school_age_children ||
          offer.jobber.preteens ||
          offer.jobber.at_caregiver_home ||
          offer.jobber.at_parents_home ||
          offer.jobber.age ||
          offer.jobber.city ||
          offer.jobber.job_distance ||
          offer.jobber.baby_sitter_kind) && (
          <Block marginBottom={spacings.s}>
            <OfferBabySitting
              license_number={offer.jobber.license_number}
              toddlers={offer.jobber.toddlers}
              preschoolers={offer.jobber.preschoolers}
              school_age_children={offer.jobber.school_age_children}
              preteens={offer.jobber.preteens}
              at_caregiver_home={offer.jobber.at_caregiver_home}
              at_parents_home={offer.jobber.at_parents_home}
              age={offer.jobber.age}
              city={offer.jobber.city}
              job_distance={offer.jobber.job_distance}
              baby_sitter_kind={offer.jobber.baby_sitter_kind}
              certificates={offer.jobber.certificates}
              is_smoker={offer.jobber.is_smoker}
              has_driving_license={offer.jobber.has_driving_license}
            />
          </Block>
        )}
        {(offer.jobber.diploma ||
          offer.jobber.certificates?.length > 0 ||
          offer.jobber.experience ||
          offer.jobber.stuffs?.length > 0 ||
          offer.jobber.commitments?.length > 0 ||
          offer.category_paid_offers_count > 0) && (
          <Block marginBottom={spacings.s}>
            <OfferSkills
              diploma={offer.jobber.diploma}
              certificates={offer.jobber.certificates}
              experience={offer.jobber.experience}
              commitments={offer.jobber.commitments}
              stuffs={offer.jobber.stuffs}
              category_paid_offers_count={offer.category_paid_offers_count}
              category_id={offer.category_id}
              jobberId={offer.jobber.id}
              baby_sitting={{
                child_toddlers: offer.child_toddlers,
                child_preschoolers: offer.child_preschoolers,
                child_school_age_children: offer.child_school_age_children,
                agrement_number: offer.agrement_number,
                child_minder: offer.child_minder,
              }}
            />
          </Block>
        )}
        {offer.jobber.vehicles?.length > 0 && (
          <Block marginBottom={spacings.s}>
            <Trucks vehicles={offer.jobber.vehicles} />
          </Block>
        )}
        {offer.last_rate && (
          <Block marginBottom={spacings.s}>
            <OfferLastRate
              body={offer.last_rate.comment}
              firstName={offer.last_rate.customer.first_name}
              rate={offer.last_rate.rate}
              badge={offer.last_rate.badge}
              createdAt={offer.last_rate.created_at}
            />
          </Block>
        )}
        {isPoster && offer.unavailable && (
          <Block marginBottom={spacings.s}>
            <Alert.Low kind={ALERT.KIND.WARNING}>
              Ce prestataire a déjà été réservé sur ce créneau.
            </Alert.Low>
          </Block>
        )}
        {isPoster &&
          (offer.selectable || (offer.selectable && offer.unavailable)) && (
            <Block marginBottom={spacings.s}>
              <PendingActions offer={offer} unavailable={offer.unavailable} />
            </Block>
          )}
      </>
    );
  }
  return <div />;
};

const JobberAvatar = ({ jobber, onClick }) => {
  const breakpoints = useBreakpoints();

  return (
    <UserPreview id={jobber.id} kind={JOBBER}>
      <Link href={jobber.url} onClick={onClick}>
        <Avatar
          isVerified
          isTopJobber={jobber.is_top_jobber}
          src={jobber.avatar}
          size={breakpoints.get({ xs: MOBILE_AVATAR_HEIGHT, md: sizes.size96 })}
          name={jobber.first_name}
        />
      </Link>
    </UserPreview>
  );
};

const OfferPrice = ({ offer }) => (
  <H4 align="right">
    {polyglot.toSmartCurrency(offer.price || offer.price_per_hour, {
      noDecimal: true,
      pricePerHour: !offer.price,
    })}
  </H4>
);

const OfferState = ({ offer }) => {
  if (offer.state === OFFER_STATE_PAID)
    return (
      <Tag.Small
        kind={TAG.KIND.SUCCESS}
        LeftComponent={() => <Icon.Small name="check-circle-solid" />}
      >
        {polyglot.t("common.paid")}
      </Tag.Small>
    );
  if (offer.state === OFFER_STATE_CANCELED)
    return (
      <Tag.Small
        kind={TAG.KIND.DANGER}
        LeftComponent={() => <Icon.Small name="user-times-solid" />}
      >
        {polyglot.t("common.canceled")}
      </Tag.Small>
    );
  return null;
};

const JobOfferItem = ({ offer, divider }) => {
  const { isPoster } = useJob();

  return (
    <OfferWrapper divider={divider}>
      <Block
        display="flex"
        alignItems="start"
        paddingX={{ xs: spacings.m, lg: spacings.ml }}
        paddingTop={{ xs: spacings.m, lg: spacings.ml }}
        paddingBottom={{
          xs: `calc(${spacings.m} - ${spacings.s})`,
          lg: `calc(${spacings.ml} - ${spacings.s})`,
        }}
      >
        {/* WEB ONLY AVATAR */}
        <Block marginRight={spacings.m} display={{ xs: "none", lg: "block" }}>
          <JobberAvatar jobber={offer.jobber} />
        </Block>
        {/* END WEB ONLY AVATAR */}

        <Block flex="1">
          <Block display="flex" alignItems="center">
            <Block flex="1">
              <Block
                display="flex"
                alignItems="start"
                marginBottom={spacings.s}
              >
                <Block
                  marginRight={spacings.s}
                  display={{ xs: "block", lg: "none" }}
                >
                  <JobberAvatar jobber={offer.jobber} />
                </Block>

                {/* HEADER CONTENT  */}
                <Block
                  width="100%"
                  display="flex"
                  minHeight={{ xs: MOBILE_AVATAR_HEIGHT, lg: "inherit" }}
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Block display="flex" alignItems="baseline" width="100%">
                    <Block flex="1" marginRight={spacings.m}>
                      <Block display="inline-block">
                        <UserPreview id={offer.jobber.id} kind={JOBBER}>
                          <Link href={offer.jobber.url} color={colors.body}>
                            <StatusEnhancer isPro={offer.jobber.is_pro}>
                              <H4 numberOfLines={2}>
                                {offer.jobber.first_name}
                              </H4>
                            </StatusEnhancer>
                          </Link>
                        </UserPreview>
                      </Block>
                    </Block>
                    {((isPoster && offer.state !== OFFER_STATE_SELECTED) ||
                      !isPoster) && (
                      <Block position="relative">
                        <OfferPrice offer={offer} />
                        <Block
                          position="absolute"
                          right={0}
                          width="max-content"
                        >
                          <OfferState offer={offer} />
                        </Block>
                      </Block>
                    )}
                  </Block>
                  <Rating
                    rate={offer.jobber.rate}
                    count={offer.jobber.rates_count}
                    type="compact"
                  />
                  <OfferTags offer={offer} />
                </Block>
                {/* END HEADER CONTENT  */}
              </Block>
            </Block>
            {isPoster && offer.state === OFFER_STATE_SELECTED && (
              <OfferPhoneChat offer={offer} />
            )}
          </Block>
          <StateSpecific offer={offer} />
        </Block>
      </Block>
    </OfferWrapper>
  );
};

export default JobOfferItem;
