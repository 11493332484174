import AppleBadgeFr from "images/app/apple-badge-fr.svg";
import GoogleBadgeFr from "images/app/google-play-badge-fr.svg";
import AppleBadgeEn from "images/app/apple-badge-en.svg";
import GoogleBadgeEn from "images/app/google-play-badge-en.svg";
import AppleBadgeEs from "images/app/apple-badge-es.svg";
import GoogleBadgeEs from "images/app/google-play-badge-es.svg";
import AppleBadgeIt from "images/app/apple-badge-it.svg";
import GoogleBadgeIt from "images/app/google-play-badge-it.svg";
import AppleBadgePt from "images/app/apple-badge-pt.svg";
import GoogleBadgePt from "images/app/google-play-badge-pt.svg";
import AppleBadgeNl from "images/app/apple-badge-nl.svg";
import GoogleBadgeNl from "images/app/google-play-badge-nl.svg";
import polyglot from "@/utils/polyglot";

export function getStoreBadgesSrc() {
  if (polyglot.locale === "en") {
    return { android: GoogleBadgeEn, ios: AppleBadgeEn };
  } else if (polyglot.locale === "es") {
    return { android: GoogleBadgeEs, ios: AppleBadgeEs };
  } else if (polyglot.locale === "it") {
    return { android: GoogleBadgeIt, ios: AppleBadgeIt };
  } else if (polyglot.locale === "pt") {
    return { android: GoogleBadgePt, ios: AppleBadgePt };
  } else if (polyglot.locale === "nl") {
    return { android: GoogleBadgeNl, ios: AppleBadgeNl };
  }
  return { android: GoogleBadgeFr, ios: AppleBadgeFr };
}
