import { artwork } from "@/assets/categories";
import { borderWidth, colors, radius, spacings } from "@/assets/themes";
import { Block, Body16, Card } from "@/components";
import { useBreakpoints } from "@/modules/hooks";
import { getCategoryFormUrlById } from "@/utils";
import polyglot from "@/utils/polyglot";

const CategoryItem = ({ size, id, name, src, url_params, category_id }) => {
  const breakpoints = useBreakpoints();

  function getSize() {
    if (size === "large") {
      return breakpoints.get({ xs: "140px", md: "140px" });
    }
    return breakpoints.get({ xs: "120px", md: "120px" });
  }
  return (
    <Card
      href={getCategoryFormUrlById(category_id || id, url_params)}
      headerImage={src || artwork[id]}
      css={`
        width: 100%;
        border-radius: 0;
        ${Card.Styled.StyledHeaderImage} {
          border-radius: ${radius.ml};
          border: solid ${borderWidth.s} ${colors.border};
          height: ${getSize()};
          &:before {
            background-position: right bottom;
          }
        }
      `}
    >
      <Block marginTop={spacings.s}>
        <Body16 strong color={colors.body} numberOfLines={1}>
          {name || polyglot.t(`categories.${id}.name`)}
        </Body16>
        {/* <Block display="inline-block" marginTop={spacings.xs}>
          <Tag.Small kind={TAG.KIND.MUTED}>
            {polyglot.t("common.average_price_per_hour", {
              price_per_hour: polyglot.toSmartCurrency(
                category.price_per_hour_average,
                { pricePerHour: true }
              ),
            })}
          </Tag.Small>
        </Block> */}
      </Block>
    </Card>
  );
};

export default CategoryItem;
