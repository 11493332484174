import YPJLogo from "images/brand/yoojo-logo@white.svg";
import giftCardWave from "images/gift-card/gift-card-wave.svg";
import polyglot from "../utils/polyglot";

const GiftCard = ({
  name = "",
  preview = false,
  amount = "",
  currency = "€",
  demoCard = false,
  className = "",
  size = "",
}) => {
  const giftIcon = ({ fill }) => (
    <svg
      height="115"
      viewBox="0 0 123 115"
      width="123"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <path
            d="m9 36h37c2.7614237 0 5 2.2385763 5 5v43c0 1.6568542-1.3431458 3-3 3h-39c-1.65685425 0-3-1.3431458-3-3v-45c0-1.6568542 1.34314575-3 3-3z"
            transform="translate(5 26)"
          />
          <path d="m49 0h15v4h-15z" transform="translate(5 26)" />
          <path
            d="m65 36h37c2.761424 0 5 2.2385763 5 5v43c0 1.6568542-1.343146 3-3 3h-39c-1.6568542 0-3-1.3431458-3-3v-45c0-1.6568542 1.3431458-3 3-3z"
            transform="matrix(-1 0 0 1 174 26)"
          />
          <path
            d="m62 3h46c2.761424 0 5 2.23857625 5 5v14c0 1.6568542-1.343146 3-3 3h-45c-1.6568542 0-3-1.3431458-3-3z"
            transform="translate(5 26)"
          />
          <path
            d="m0 3h46c2.7614237 0 5 2.23857625 5 5v14c0 1.6568542-1.3431458 3-3 3h-45c-1.65685425 0-3-1.3431458-3-3z"
            transform="matrix(-1 0 0 1 56 26)"
          />
        </g>
        <g stroke={fill} strokeWidth="9">
          <path
            d="m34.0854281 18.302421c-6.6542303 1.3624377-13.8649785 2.0436566-21.6322447 2.0436566-11.65089919 0-19.57680089-14.54159134-2.94357056-20.14279689 6.96103206-.81537363 15.15297046 3.41588672 24.57581526 12.69378099z"
            transform="translate(27 6)"
          />
          <path
            d="m69.0854281 18.302421c-6.6542303 1.3624377-13.8649785 2.0436566-21.6322447 2.0436566-11.6508992 0-19.5768009-14.54159134-2.9435706-20.14279689 6.9610321-.81537363 15.1529705 3.41588672 24.5758153 12.69378099z"
            transform="matrix(-1 0 0 1 131.085 6)"
          />
        </g>
      </g>
    </svg>
  );

  const renderSize = () => {
    switch (size) {
      case "medium":
        return "gift-card-size-medium";
      case "small":
        return "gift-card-size-size";
      default:
        return "";
    }
  };

  const renderNames = () => {
    const names = [
      "un ami",
      "un collègue",
      "un frère",
      "une soeur",
      "une mère",
      "un père",
      "un oncle",
      "une tante",
    ];
    let index = 0;
    const result = setInterval(() => {
      index = (index + 1) % names.length;
      return names[index];
    }, 300);
    return result;
  };

  return (
    <div className={`gift-card ${className} ${renderSize()}`}>
      <div className="gift-card-blue-side">
        <div className={`gift-card-text ${demoCard ? "demo-card" : ""}`}>
          <img src={YPJLogo} alt="" style={{ width: 70 }} />
          {demoCard ? null : (
            <div className="gift-card-text-recipient">
              <p>
                {polyglot.t("common.for")}
                <span className="gift-card-text-recipient-name">
                  {" "}
                  {preview ? renderNames() : name}
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="gift-card-bg-icons">
          <div className="i-gift i-gift--small">
            {giftIcon({ fill: "rgb(44, 125, 232)" })}
          </div>
        </div>
      </div>
      {demoCard ? (
        <p className="condition-text">
          {polyglot.t("gift_cards.new.validity_explanation")}
        </p>
      ) : (
        <div className="gift-card-white-side">
          <div className="gift-card-label">
            <p>{polyglot.t("common.gift_card")}</p>
            <img className="gift-card-wave" src={giftCardWave} alt="" />
          </div>
          <div className="gift-card-price">
            <span className="price-label">{amount}</span>
            <span className="price-label-currency">{currency}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftCard;
