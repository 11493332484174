import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { colors, radius, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Body20,
  ConditionnalWrapper,
  H1,
  H5,
  Icon,
  Link,
  List,
  Modal,
  StatusEnhancer,
  Tag,
} from "../../../components";
import OfferJobberRating from "../../../components/JobOfferItem/OfferJobberRating";
import { TAG } from "../../../components/Styles/variants";
import { useGetTransactionDetails } from "../../../modules/routes/dashboard-routes";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import { JOB, JOBBER } from "../../../pages-admin/UserPreview/config";
import polyglot from "../../../utils/polyglot";
import TransactionDetailsModalSkeleton from "../skeletons/TransactionDetailsModalSkeleton";
import { formatDate } from "@/utils";
import { getTransactionDetailsPath } from "@/utils/get-transaction-details-path";

const hasData = (a) =>
  a ? Object.keys(a).filter((key) => a[key] !== null).length > 0 : false;

const AditionnalInformations = ({
  state,
  job_title,
  job_url,
  job_date,
  job_id,
  jobber,
  duration,
  payment_date,
  initial_duration,
  extra_duration,
  total_duration,
  cancellation_date,
}) => (
  <>
    <List.Header as={H5}>
      {polyglot.t("transaction_details.further_informations")}
    </List.Header>
    {state && (
      <List.Item
        RightComponent={() => (
          <>
            {state === "paid" && (
              <Tag.Medium
                kind={TAG.KIND.SUCCESS}
                LeftComponent={() => <Icon.Medium name="check-circle-solid" />}
              >
                {polyglot.t("common.paid")}
              </Tag.Medium>
            )}
            {state === "canceled" && (
              <Tag.Medium
                kind={TAG.KIND.DANGER}
                LeftComponent={() => <Icon.Medium name="user-times-solid" />}
              >
                {polyglot.t("common.canceled")}
              </Tag.Medium>
            )}
          </>
        )}
      >
        {polyglot.t("common.status")}
      </List.Item>
    )}
    {job_title && (
      <ConditionnalWrapper
        condition={job_id}
        wrapper={(children) => (
          <UserPreview id={job_id} kind={JOB}>
            {children}
          </UserPreview>
        )}
      >
        <List.Item
          RightComponent={() => (
            <Link href={job_url} numberOfLines={1}>
              {job_title}
            </Link>
          )}
        >
          {polyglot.t("common.service")}
        </List.Item>
      </ConditionnalWrapper>
    )}
    {job_date && (
      <List.Item
        RightComponent={() => <List.Elem.Label>{job_date}</List.Elem.Label>}
      >
        {polyglot.t("transaction_details.job_date")}
      </List.Item>
    )}
    {jobber && (
      <ConditionnalWrapper
        condition={jobber?.id}
        wrapper={(children) => (
          <UserPreview id={jobber?.id} kind={JOBBER}>
            {children}
          </UserPreview>
        )}
      >
        <List.Item
          RightComponent={() => <List.Elem.Label>{jobber}</List.Elem.Label>}
        >
          {polyglot.t("common.jobber")}
        </List.Item>
      </ConditionnalWrapper>
    )}
    {duration && (
      <List.Item
        RightComponent={() => <List.Elem.Label>{duration}</List.Elem.Label>}
      >
        {polyglot.t("common.duration")}
      </List.Item>
    )}
    {initial_duration && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{initial_duration}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.initial_duration")}
      </List.Item>
    )}
    {extra_duration && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{extra_duration}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.extra_hours")}
      </List.Item>
    )}
    {total_duration && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{total_duration}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.total_duration")}
      </List.Item>
    )}
    {payment_date && (
      <List.Item
        RightComponent={() => <List.Elem.Label>{payment_date}</List.Elem.Label>}
      >
        {polyglot.t("common.payment_date")}
      </List.Item>
    )}
    {cancellation_date && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{cancellation_date}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.cancellation_date")}
      </List.Item>
    )}
  </>
);

const PriceInformations = ({
  jobber_price,
  taxes,
  fees,
  extended_cover,
  voucher_code,
  voucher_amount,
}) => (
  <>
    <List.Header as={H5}>
      {polyglot.t("transaction_details.price_details")}
    </List.Header>
    {jobber_price >= 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>
            {polyglot.toSmartCurrency(jobber_price)}
          </List.Elem.Label>
        )}
      >
        {polyglot.t("transaction_details.jobber_price")}
      </List.Item>
    )}
    {taxes > 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{polyglot.toSmartCurrency(taxes)}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.taxes")}
      </List.Item>
    )}
    {fees >= 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{polyglot.toSmartCurrency(fees)}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.fees")}
      </List.Item>
    )}
    {extended_cover > 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{polyglot.toSmartCurrency(extended_cover)}</List.Elem.Label>
        )}
      >
        {polyglot.t("common.extended_cover")}
      </List.Item>
    )}
    {voucher_code && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>
            -{polyglot.toSmartCurrency(voucher_amount)}
          </List.Elem.Label>
        )}
      >
        {polyglot.t("transaction_details.voucher_code_code", {
          code: voucher_code,
        })}
      </List.Item>
    )}
  </>
);

const PaymentInformations = ({
  cesu,
  wallet,
  payment_method,
  price_paid,
  fees_discount = 0,
}) => {
  const getPaymentMethodName = () => {
    if (payment_method === "maestro") return "Maestro";
    if (payment_method === "ideal") return "iDEAL";
    if (payment_method === "paypal") return "PayPal";
    if (payment_method === "amex") return "AMEX";
    if (payment_method === "apple_pay") return "Apple Pay";
    if (payment_method === "google_pay") return "Google Pay";
    return polyglot.t("common.credit_card");
  };

  return (
    <>
      <List.Header as={H5}>
        {polyglot.t("transaction_details.payment_details")}
      </List.Header>
      {cesu > 0 && (
        <List.Item
          RightComponent={() => (
            <List.Elem.Label>{polyglot.toSmartCurrency(cesu)}</List.Elem.Label>
          )}
        >
          {polyglot.t("transaction_details.paid_with_cesu")}
        </List.Item>
      )}

      {wallet + fees_discount > 0 && (
        <List.Item
          RightComponent={() => (
            <List.Elem.Label>
              {polyglot.toSmartCurrency(wallet + fees_discount)}
            </List.Elem.Label>
          )}
        >
          {polyglot.t("transaction_details.paid_with_wallet")}
        </List.Item>
      )}

      {price_paid > 0 && (
        <List.Item
          RightComponent={() => (
            <List.Elem.Label>
              {polyglot.toSmartCurrency(price_paid)}
            </List.Elem.Label>
          )}
        >
          {polyglot.t("transaction_details.paid_with_method", {
            method: getPaymentMethodName(),
          })}
        </List.Item>
      )}
    </>
  );
};

const RefundInformations = ({ reserved_amount, refunded_amount, fees }) => (
  <>
    <List.Header as={H5}>
      {polyglot.t("transaction_details.refund_details")}
    </List.Header>
    {reserved_amount >= 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>
            {polyglot.toSmartCurrency(reserved_amount)}
          </List.Elem.Label>
        )}
      >
        {polyglot.t("transaction_details.prepaid_amount")}
      </List.Item>
    )}
    {refunded_amount >= 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>
            {polyglot.toSmartCurrency(refunded_amount)}
          </List.Elem.Label>
        )}
      >
        {polyglot.t("transaction_details.refunded_wallet_amount")}
      </List.Item>
    )}
    {fees > 0 && (
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{polyglot.toSmartCurrency(fees)}</List.Elem.Label>
        )}
      >
        {polyglot.t("transaction_details.retained_service_fees")}
      </List.Item>
    )}
  </>
);

const Documents = ({ documents }) => (
  <>
    <List.Header as={H5}>{polyglot.t("routes.billings")}</List.Header>
    {documents.map((document, index) => (
      <List.Item
        key={`document-details-${index}`}
        href={document.url}
        target="_blank"
        LeftComponent={() => (
          <Icon.Large name="download" color={colors.primary} />
        )}
      >
        <List.Elem.Title strong color={colors.primary}>
          {document.name}
        </List.Elem.Title>
      </List.Item>
    ))}
  </>
);

const InstantPaymentsHistory = ({ instant_payments_history, currId }) => (
  <>
    <List.Header as={H5}>
      {polyglot.t("transaction_details.instant_payment_history")}
    </List.Header>
    {instant_payments_history.map((payment, index) => (
      <List.Item
        key={`instant-payment-history-details-${index}`}
        href={getTransactionDetailsPath({
          last_instant_payment: { id: payment.id },
        })}
        RightComponent={() => (
          <Body16 color={colors.muted}>
            {polyglot.toSmartCurrency(payment.price)}
          </Body16>
        )}
      >
        <List.Elem.Title
          strong
          color={payment.id === currId ? colors.mutedLight : colors.primary}
        >
          {formatDate(payment.date, "DD/MM/YYYY")}
        </List.Elem.Title>
      </List.Item>
    ))}
  </>
);

const TransactionDetailsModal = ({
  isOpen = true,
  onClose,
  pathname: propsPathname,
}) => {
  const location = useLocation();
  const {
    data,
    isLoading = true,
    refetch,
  } = useGetTransactionDetails(propsPathname || location?.pathname, {
    enabled: false,
  });
  const history = useHistory();

  const handleRateSuccess = () => {
    refetch();
  };

  const handleAnimationComplete = () => {
    refetch();
  };

  const handleClose = () => {
    // handle react_app modal trigger with onClose
    if (!onClose) {
      if (!history?.location?.key) {
        history?.replace("/account/wallet");
      } else {
        history?.goBack();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal.Small
      isOpen={isOpen}
      onClose={handleClose}
      fullScreenOnMobile
      onAnimationComplete={handleAnimationComplete}
    >
      <Modal.Item.Header onClose={handleClose} />
      {!isLoading && data ? (
        <>
          <Block
            justifyContent="center"
            marginBottom={spacings.s}
            marginX={{ xs: spacings.s, sm: spacings.l }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Block marginBottom={spacings.s}>
              {data.jobber && (
                <ConditionnalWrapper
                  condition={data.jobber?.id}
                  wrapper={(children) => (
                    <UserPreview id={data.jobber?.id} kind={JOBBER}>
                      {children}
                    </UserPreview>
                  )}
                >
                  <Link href={data.jobber.url}>
                    <Avatar
                      size={sizes.size96}
                      src={data.jobber.avatar}
                      name={data.jobber.first_name}
                    />
                  </Link>
                </ConditionnalWrapper>
              )}
              {data.title && (
                <Block
                  borderRadius={radius.circle}
                  width={sizes.size96}
                  height={sizes.size96}
                  display="flex"
                  backgroundColor={colors.orangered50}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon.XLarge
                    name="yoojo-direct"
                    color={colors.orangered500}
                    size={sizes.size64}
                  />
                </Block>
              )}
            </Block>
            <Block marginBottom={spacings.s}>
              {data.jobber && (
                <ConditionnalWrapper
                  condition={data.jobber?.id}
                  wrapper={(children) => (
                    <UserPreview id={data.jobber?.id} kind={JOBBER}>
                      {children}
                    </UserPreview>
                  )}
                >
                  <StatusEnhancer isPro={data.jobber.is_pro}>
                    <Link href={data.jobber.url} color={colors.body}>
                      <Body20 numberOfLines={1}>
                        {data.jobber.first_name}
                      </Body20>
                    </Link>
                  </StatusEnhancer>
                </ConditionnalWrapper>
              )}
              {data.title && <Body20>{data.title}</Body20>}
            </Block>
            {data.total && !data.refund_informations && (
              <H1>-{polyglot.toSmartCurrency(data.total)}</H1>
            )}
            {data.total && data.refund_informations && (
              <H1>+{polyglot.toSmartCurrency(data.total)}</H1>
            )}
            {data.refund_informations && (
              <Block marginTop={spacings.s}>
                <Tag.Medium
                  kind={TAG.KIND.WHITE}
                  LeftComponent={() => (
                    <Icon.Medium name="check-circle-solid" />
                  )}
                >
                  {polyglot.t("transaction_details.refunded")}
                </Tag.Medium>
              </Block>
            )}
          </Block>
          <Modal.Item.Wrapper>
            {hasData(data.additional_informations) && (
              <AditionnalInformations {...data.additional_informations} />
            )}
            {hasData(data.price_informations) && (
              <PriceInformations {...data.price_informations} />
            )}
            {hasData(data.payment_informations) && (
              <PaymentInformations
                {...data.payment_informations}
                fees_discount={data.price_informations?.fees_discount}
              />
            )}
            {hasData(data.refund_informations) && (
              <RefundInformations {...data.refund_informations} />
            )}
            {hasData(data.documents) && (
              <Documents documents={data.documents} />
            )}
            {hasData(data.instant_payments_history) &&
              data.instant_payments_history.length > 1 && (
                <InstantPaymentsHistory
                  currId={data.id}
                  instant_payments_history={data.instant_payments_history}
                />
              )}
            {(data.rateable || data.rate) && (
              <>
                <OfferJobberRating
                  id={data.additional_informations?.offer_id || data.id}
                  rate={data.rate}
                  rateable={data.rateable}
                  jobber={data.jobber}
                  openInModal
                  onSuccess={handleRateSuccess}
                  renderHeader={({ children, ...rest }) => (
                    <List.Header as={H5} RightComponent={rest.RightComponent}>
                      {children}
                    </List.Header>
                  )}
                />
              </>
            )}
          </Modal.Item.Wrapper>
        </>
      ) : (
        <TransactionDetailsModalSkeleton />
      )}
    </Modal.Small>
  );
};

const areEqual = (p, n) => p.isOpen === n.isOpen;
export default React.memo(TransactionDetailsModal, areEqual);
