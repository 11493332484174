import ReactDOM from "react-dom";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { borderWidth, colors, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Carousel,
  Container,
  Icon,
  Info,
  List,
} from "../../../components";
import { useBreakpoints, useCategoryForm } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";

const StyledContainer = styled(Block)`
  border-bottom: solid ${borderWidth.m} ${colors.border};
  margin-top: -1px;
  background: ${colors.background};
  > div > div > div {
    width: 100%;
  }
`;

const PrivateJobberBanner = () => {
  const { jobbers } = useCategoryForm();
  const { values } = useFormikContext();

  const { avatar, first_name } = useMemo(
    () => jobbers.find((jobber) => jobber.id === values.private_jobber_id),
    [jobbers, values.private_jobber_id]
  );

  const View = (
    <StyledContainer paddingX={spacings.ml}>
      <Container.XSmall>
        <Block display="flex" alignItems="center" justifyContent="space-around">
          <List.Item
            divider={false}
            LeftComponent={() => <Avatar size={sizes.size48} src={avatar} />}
          >
            <Block spaceY={spacings.xxs}>
              <Body14>{polyglot.t("common.booking")}</Body14>
              <Body16 strong>{first_name}</Body16>
            </Block>
          </List.Item>
        </Block>
      </Container.XSmall>
    </StyledContainer>
  );
  return ReactDOM.createPortal(
    View,
    document.getElementsByTagName("header")[0]
  );
};
export default PrivateJobberBanner;
