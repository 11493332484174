import { useState } from "react";
import {
  Accordion,
  Block,
  Body14,
  Body16,
  H3,
  Icon,
  List,
  Modal,
  Shape,
  Tabs,
} from "@/components";
import polyglot from "@/utils/polyglot";
import { colors, spacings } from "@/assets/themes";
import { LIST, SHAPE } from "@/components/Styles/variants";
import { YOOJO_COVER_PRICE, YOOJO_EXTENDED_COVER_PRICE } from "@/utils";

const ExtendedCoverModal = ({ onClose, isOpen, tab = 0 }) => {
  const [activeTab, setActiveTab] = useState(tab);

  const currAmount =
    activeTab === 0 ? YOOJO_EXTENDED_COVER_PRICE : YOOJO_COVER_PRICE;

  const items = [
    {
      title: polyglot.t("yoojo_cover.accordion.title1"),
      subtitle: polyglot.t("yoojo_cover.free_without_fees"),
      body: polyglot.t("yoojo_cover.accordion.description1", {
        amount: polyglot.toSmartCurrency(currAmount, {
          noDecimal: true,
        }),
      }),
    },
    {
      title: polyglot.t("yoojo_cover.accordion.title2"),
      subtitle: polyglot.t("yoojo_cover.max_insurance_price", {
        amount: polyglot.toSmartCurrency(currAmount, {
          noDecimal: true,
        }),
      }),
      body: polyglot.t("yoojo_cover.accordion.description2", {
        amount: polyglot.toSmartCurrency(currAmount, {
          noDecimal: true,
        }),
      }),
    },
    {
      title: polyglot.t("yoojo_cover.accordion.title3"),
      subtitle: polyglot.t("yoojo_cover.max_insurance_price", {
        amount: polyglot.toSmartCurrency(currAmount, {
          noDecimal: true,
        }),
      }),
      body: polyglot.t("yoojo_cover.accordion.description3", {
        amount: polyglot.toSmartCurrency(currAmount, {
          noDecimal: true,
        }),
      }),
    },
    {
      title: polyglot.t("yoojo_cover.accordion.title4"),
      subtitle: polyglot.t("yoojo_cover.max_insurance_price", {
        amount: polyglot.toSmartCurrency(YOOJO_COVER_PRICE, {
          noDecimal: true,
        }),
      }),
      body: polyglot.t("yoojo_cover.accordion.description4", {
        amount: polyglot.toSmartCurrency(YOOJO_COVER_PRICE, {
          noDecimal: true,
        }),
      }),
    },
  ];

  const noCoverItems = [
    polyglot.t("yoojo_cover.list.title1"),
    polyglot.t("yoojo_cover.list.title2"),
    polyglot.t("yoojo_cover.list.title3"),
    polyglot.t("yoojo_cover.list.title4"),
    polyglot.t("yoojo_cover.list.title5"),
  ];

  return (
    <Modal.Small onClose={onClose} isOpen={isOpen} fullScreenOnMobile>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("common.cover")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Tabs value={activeTab} onChange={setActiveTab} fill>
        <Tabs.Tab value={0}>
          <span>{polyglot.t("common.extended_cover_short")}</span>
          <Icon.Large
            name="shield-plus-solid"
            css={`
              margin-left: ${spacings.xs};
            `}
          />
        </Tabs.Tab>
        <Tabs.Tab value={1}>{polyglot.t("common.cover_short")}</Tabs.Tab>
      </Tabs>
      <Modal.Item.Wrapper>
        <Block marginTop={spacings.m}>
          <List.Item
            withGutters
            divider={false}
            shape={LIST.SHAPE.ROUND}
            css={`
              background-color: ${colors.backgroundLight};
            `}
            LeftComponent={() => (
              <Shape.Large
                shape={SHAPE.SHAPE.CIRCLE}
                backgroundColor={
                  activeTab === 0 ? colors.primary : colors.muted
                }
              >
                <Icon.XLarge
                  name={activeTab === 0 ? "shield-plus-solid" : "shield-half"}
                />
              </Shape.Large>
            )}
          >
            <H3>
              {polyglot.t("yoojo_cover.max_insurance_price", {
                amount: polyglot.toSmartCurrency(currAmount, {
                  noDecimal: true,
                }),
              })}
            </H3>
            <Body14 color={colors.muted}>
              {activeTab === 0
                ? polyglot.t("yoojo_cover.banner.plus.title")
                : polyglot.t("yoojo_cover.banner.basic.title")}
            </Body14>
          </List.Item>
        </Block>
        <List.Header>{polyglot.t("yoojo_cover.heading.title1")}</List.Header>
        <Accordion.Group>
          {items.map((item, index) => (
            <Accordion
              key={`extended-cover-${index}`}
              title={item.title}
              subtitle={item.subtitle}
            >
              <Body16>{item.body}</Body16>
            </Accordion>
          ))}
        </Accordion.Group>
        {activeTab === 0 && (
          <Block marginTop={spacings.m}>
            <Body14 color={colors.muted}>
              {polyglot.t("yoojo_cover.cancellation_refund")}
            </Body14>
          </Block>
        )}
        <List.Header>{polyglot.t("yoojo_cover.heading.title2")}</List.Header>
        <List.Group>
          {noCoverItems.map((item, index) => (
            <List.Item
              key={`no-cover-${index}`}
              LeftComponent={() => <Icon.Large name="circle-small-solid" />}
            >
              <Body16>{item}</Body16>
            </List.Item>
          ))}
        </List.Group>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default ExtendedCoverModal;
