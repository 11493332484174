import GiftWrapping from "images/christmas24/gift_wrapping.svg";
import InstallDecoration from "images/christmas24/install_decoration.svg";
import InstallTree from "images/christmas24/install_tree.svg";
import { Snowfall } from "react-snowfall";
import moment from "moment";
import { artwork } from "@/assets/categories";
import ChristmasSrc from "@/assets/images/banners/christmas-starbucks-bg.svg";
import ChristmasRcSrc from "@/assets/images/banners/christmas-starbucks-rc.svg";
import { colors, spacings } from "@/assets/themes";
import { Banner, Block, Body14, Body16, List } from "@/components";
import { BANNER } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";
import CategoriesVertical from "./Blocks/CategoriesVertical";
import JobbersGrid from "./Blocks/JobbersGrid";

const getMockedChristmasCategories = () => ({
  categories: [
    {
      name: polyglot.t("christmas24.cleaning"),
      category_id: 4001,
      src: artwork[4001],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "menage-fete",
        title: polyglot.t("christmas24.cleaning"),
      },
    },
    {
      name: polyglot.t("christmas24.pet_sitting"),
      category_id: 5009,
      src: artwork[5009],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "garde-animaux-noel",
        title: polyglot.t("christmas24.pet_sitting"),
      },
    },
    {
      name: polyglot.t("categories.4009.name"),
      category_id: 4009,
      src: artwork[4009],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "lavage-vitre-noel",
      },
    },
    {
      name: polyglot.t("christmas24.finalise_moving"),
      category_id: 1104,
      src: artwork[3014],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "finaliser-amenagement",
        title: polyglot.t("christmas24.finalise_moving"),
      },
    },
    {
      name: polyglot.t("christmas24.repair_thing"),
      category_id: 1037,
      src: artwork[1037],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "reparer-truc",
        title: polyglot.t("christmas24.repair_thing"),
      },
    },
    {
      name: polyglot.t("christmas24.install_tree"),
      category_id: 1104,
      src: InstallTree,
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "installation-sapin",
        title: polyglot.t("christmas24.install_tree"),
      },
    },
    {
      name: polyglot.t("categories.3003.name"),
      category_id: 3003,
      src: artwork[3003],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "deplacer-objet",
        title: polyglot.t("categories.3003.name"),
      },
    },
    {
      name: polyglot.t("christmas24.install_decoration"),
      category_id: 1104,
      src: InstallDecoration,
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "installation-decoration-noel",
        title: polyglot.t("christmas24.install_decoration"),
      },
    },
    {
      name: polyglot.t("categories.2027.name"),
      category_id: 2027,
      src: artwork[2027],
      url_params: { "utm-source": "hiver-2024", "utm-campaign": "couper-bois" },
    },
    {
      name: polyglot.t("categories.2010.name"),
      category_id: 2010,
      src: artwork[2010],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "ranger-du-bois",
      },
    },
    {
      name: polyglot.t("categories.2014.name"),
      category_id: 2014,
      src: artwork[2014],
      url_params: { "utm-source": "hiver-2024", "utm-campaign": "deneiger" },
    },
    {
      name: polyglot.t("categories.2016.name"),
      category_id: 2016,
      src: artwork[2016],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "saler-maison",
      },
    },
    {
      name: polyglot.t("christmas24.gift_wrapping"),
      category_id: 1104,
      src: GiftWrapping,
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "emballage-cadeaux",
        title: polyglot.t("christmas24.gift_wrapping"),
      },
    },
    {
      name: polyglot.t("christmas24.remove_tree"),
      category_id: 1104,
      src: artwork[3016],
      url_params: {
        "utm-source": "hiver-2024",
        "utm-campaign": "enlever-sapin",
        title: polyglot.t("christmas24.remove_tree"),
      },
    },
  ],
});

const HomeChristmas = () => {
  const data = getMockedChristmasCategories();
  const breakpoints = useBreakpoints();

  const isStarbucksVisible = () => {
    const today = moment();
    const endDate = moment("2024-12-31", "YYYY-MM-DD");
    return today.isSameOrBefore(endDate);
  };

  return (
    <Block>
      <Snowfall
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
        }}
      />
      <List.Header>
        <span>{polyglot.t("christmas24.title")}</span>
        <Body16
          css={`
            font-weight: 400;
          `}
        >
          {polyglot.t("christmas24.subtitle")}
        </Body16>
      </List.Header>
      {isStarbucksVisible() && (
        <>
          <Banner
            title={polyglot.t("christmas24.starbucks.title")}
            subtitle={`${polyglot.t("christmas24.starbucks.subtitle")}*`}
            backgroundImage={ChristmasSrc}
            rightBackgroundImage={ChristmasRcSrc}
            size={breakpoints.get({
              xs: BANNER.SIZE.MEDIUM,
              xl: BANNER.SIZE.LARGE,
            })}
          />
          <Block marginTop={spacings.s}>
            <Body14
              color={colors.muted}
              strong
              css={`
                font-weight: 400;
              `}
            >
              {polyglot.t("christmas24.starbucks.subtitle_star")}
            </Body14>
          </Block>
        </>
      )}
      <Block marginTop={spacings.ml}>
        {data.categories?.length > 0 && (
          <>
            <CategoriesVertical
              block={{ category_id: null, data: data.categories }}
            />
          </>
        )}
      </Block>
      {data.jobbers?.length > 0 && (
        <>
          <List.Header>{data.jobbers_title}</List.Header>
          <JobbersGrid block={{ data: data.jobbers }} hideJobbersSeeMore />
        </>
      )}
    </Block>
  );
};

export default HomeChristmas;
