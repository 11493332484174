import { spacings } from "@/assets/themes";
import { Banner, Block, Carousel } from "@/components";
import { BANNER } from "@/components/Styles/variants";
import {
  useBanners,
  useBreakpoints,
  useGoogleAnalytics,
} from "@/modules/hooks";
import { getHomeBannerPressEvent } from "@/utils/analytics-events";

const BannerHorizontal = ({ block }) => {
  const { banners } = useBanners();
  const breakpoints = useBreakpoints();
  const { sendEvent } = useGoogleAnalytics();

  return (
    <Block marginX={{ xs: `calc(${spacings.m} * -1)`, md: "0px" }}>
      <Carousel
        slideToShow={1}
        gutter={breakpoints.get({ xs: spacings.s, md: spacings.m })}
        paddingX={{ xs: spacings.m, md: "0px" }}
        withGutters={breakpoints.get({ xs: true, sm: false })}
      >
        {block.data
          .filter((b) => !!banners[b])
          .map((banner) => (
            <Banner
              {...banners[banner]}
              size={breakpoints.get({
                xs: BANNER.SIZE.MEDIUM,
                xl: BANNER.SIZE.LARGE,
              })}
              onClick={(e) => {
                if (banners[banner].event)
                  sendEvent(getHomeBannerPressEvent(banners[banner]));
                if (banners[banner].onClick) banners[banner].onClick(e);
              }}
              onClose={
                banners[banner].closable
                  ? () => banners[banner].hide()
                  : undefined
              }
              key={`home-banner-${banner}`}
            />
          ))}
      </Carousel>
    </Block>
  );
};

export default BannerHorizontal;
