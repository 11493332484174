import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { colors, spacings } from "../../assets/themes";
import {
  Block,
  Container,
  H1,
  H2,
  Icon,
  Link,
  List,
  Spinner,
  Toggle,
} from "../../components";
import { CardBase } from "../../components/Styles/Base";
import { LIST } from "../../components/Styles/variants";
import { useBreakpoints, useGoogleAnalytics } from "../../modules/hooks";
import {
  useAddExtendedCover,
  useGetBookingData,
  useRemoveExtendedCover,
  useSubmitBooking,
} from "../../modules/routes/jobber-booking-routes";
import { useTokenizeQuery } from "../../modules/routes/payment-routes";
import {
  paymentCreditCardValidation,
  setPaymentInitialValues,
} from "../../utils";
import {
  getBookingOpenEvent,
  getBookingSubmitEvent,
} from "../../utils/analytics-events";
import polyglot from "../../utils/polyglot";
import JobberSelectionTutorial from "../JobberSelectionTutorial";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import Aside from "./Aside";
import BookingHeader from "./BookingHeader";
import BookingSubmit from "./BookingSubmit";
import Faq from "./Faq";
import Payment from "./Payment";
import Summary from "./Summary";
import { getPricing } from "./utils";
import ExtendedCover from "./ExtendedCover";

const JobberBooking = ({
  show_phone_assistance,
  jobber,
  job,
  initial_values,
  tutorialIsOpen,
}) => {
  const [tutorialModalIsOpen, setTutorialModalIsOpen] =
    useState(tutorialIsOpen);
  const paymentMethodRef = useRef();
  const { sendEvent } = useGoogleAnalytics();
  const { data, isLoading } = useGetBookingData();
  const submit = useSubmitBooking();
  const formikRef = useRef();
  const submitBooking = useTokenizeQuery(submit.mutateAsync);
  const { kpi_kind } = job;
  const breakpoints = useBreakpoints();
  const addExtendedCover = useAddExtendedCover();
  const removeExtendedCover = useRemoveExtendedCover();

  const getJobber = () => {
    if (data?.offer?.jobber) {
      const newObj = { ...(data.offer.jobber || []) };
      newObj.url = null;
      return newObj;
    }
    return null;
  };

  const handleCloseTutorial = () => {
    setTutorialModalIsOpen(false);
  };

  const handleSubmit = (values) => {
    sendEvent(getBookingSubmitEvent({ kpi_kind }));
    submitBooking.mutate(
      { ...values, kpi_kind },
      {
        onError: (err) => {
          const mustResetCard = err.response.data?.data?.must_reset_card;
          if (mustResetCard) {
            paymentMethodRef.current?.reset();
            formikRef.current?.resetForm({
              values: {
                ...values,
                default_payment_method: "",
                payment_method: "",
                card_number: "",
                card_type: "",
                token: "",
              },
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    sendEvent(getBookingOpenEvent({ kpi_kind }));
  }, []);

  return (
    <>
      <BookingHeader />
      <Block marginY={spacings.ml}>
        <Block>
          <Container.Medium>
            <Block marginBottom={spacings.s}>
              <Link
                href={job.url}
                color={colors.muted}
                css={`
                  display: inline-block;
                `}
              >
                <Icon.Medium name="arrow-left" />
                &nbsp;
                {polyglot.t("common.back")}
              </Link>
            </Block>
          </Container.Medium>
        </Block>
        {!isLoading && data ? (
          <Formik
            validationSchema={yup.lazy((values) => {
              if (getPricing(values, { ...data }).total > 0) {
                return paymentCreditCardValidation;
              }
              return yup.object().shape({});
            })}
            validateOnMount
            initialValues={{
              ...initial_values,
              ...setPaymentInitialValues({
                ...initial_values,
                ...data,
              }),
              extended_cover: initial_values?.extended_cover || false,
              payment_method:
                initial_values.default_payment_method ||
                data.default_payment_method ||
                initial_values.payment_method ||
                data.payment_method,
              urssaf: initial_values?.urssaf || false,
              cesu: initial_values?.cesu || false,
            }}
            innerRef={formikRef}
            onSubmit={handleSubmit}
          >
            {({ isValid }) => (
              <Form>
                <Container.Medium>
                  {breakpoints.get({
                    xs: (
                      <H2>
                        {polyglot.t("booking.booking_of_first_name", {
                          first_name: jobber.first_name,
                        })}
                      </H2>
                    ),
                    md: (
                      <H1>
                        {polyglot.t("booking.booking_of_first_name", {
                          first_name: jobber.first_name,
                        })}
                      </H1>
                    ),
                  })}
                  <Block
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="row"
                    gap={spacings.l}
                  >
                    <Block flex="1" width="100%">
                      {breakpoints.get({
                        xs: (
                          <Block
                            marginTop={spacings.ml}
                            marginBottom={spacings.xs}
                          >
                            <JobberOfferItem
                              urlTargetBlank
                              jobber={getJobber()}
                              hideDetails
                              price={data.offer.price}
                              price_per_hour={data.offer.price_per_hour}
                              withGutters
                              shape={LIST.SHAPE.ROUND}
                              css={`
                                background-color: ${colors.background};
                              `}
                            />
                          </Block>
                        ),
                        md: null,
                      })}
                      {data.extended_cover_eligible && (
                        <Block marginTop={spacings.ml}>
                          <ExtendedCover
                            data={data}
                            add={addExtendedCover}
                            remove={removeExtendedCover}
                          />
                        </Block>
                      )}
                      {breakpoints.get({
                        xs: (
                          <Block>
                            <List.Header>
                              {polyglot.t("common.summary")}
                            </List.Header>
                            <CardBase flat>
                              <Block
                                paddingX={spacings.m}
                                paddingY={spacings.s}
                              >
                                <Summary
                                  tax_option={data.tax_option}
                                  isLoading={
                                    addExtendedCover.isLoading ||
                                    removeExtendedCover.isLoading
                                  }
                                />
                              </Block>
                            </CardBase>
                          </Block>
                        ),
                        md: null,
                      })}
                      <Block marginBottom={spacings.ml}>
                        <List.Header>
                          {polyglot.t("payment_methods.title")}
                        </List.Header>
                        <CardBase
                          flat
                          css={`
                            padding: ${breakpoints.get({
                              xs: `${spacings.xs} ${spacings.m}
                              ${spacings.m} ${spacings.m}`,
                              md: `${spacings.sm} ${spacings.ml}
                              ${spacings.ml} ${spacings.ml}`,
                            })};
                          `}
                        >
                          <Payment
                            payment_methods={data.payment_methods}
                            cesu={data.cesu}
                            innerRef={paymentMethodRef}
                          />
                          <Block
                            position={isValid ? "sticky" : "static"}
                            bottom={spacings.m}
                          >
                            <BookingSubmit
                              handleSubmit={handleSubmit}
                              isLoading={
                                submitBooking.isLoading ||
                                submitBooking.isSuccess
                              }
                            />
                          </Block>
                        </CardBase>
                      </Block>

                      {data?.zendesk_articles?.length > 0 && (
                        <CardBase flat>
                          <Block
                            paddingBottom={spacings.sm}
                            paddingTop={{ xs: spacings.xs, md: spacings.sm }}
                          >
                            <Block
                              paddingX={{ xs: spacings.m, md: spacings.ml }}
                            >
                              <Faq data={data?.zendesk_articles} />
                            </Block>
                          </Block>
                        </CardBase>
                      )}
                    </Block>
                    {breakpoints.get({
                      md: (
                        <Block>
                          {!data.extended_cover_eligible ? (
                            <>
                              {/* Fix visual bloc alignement */}
                              <List.Header>&nbsp;</List.Header>
                            </>
                          ) : (
                            <Block height={spacings.ml} />
                          )}
                          <Aside
                            job={data.job}
                            jobber={getJobber()}
                            offer={data.offer}
                            tax_option={data.tax_option}
                            show_phone_assistance={show_phone_assistance}
                            isLoading={
                              addExtendedCover.isLoading ||
                              removeExtendedCover.isLoading
                            }
                          />
                        </Block>
                      ),
                    })}
                  </Block>
                </Container.Medium>
              </Form>
            )}
          </Formik>
        ) : (
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin={spacings.l}
          >
            <Spinner.Medium />
          </Block>
        )}

        <JobberSelectionTutorial
          isOpen={tutorialModalIsOpen}
          onClose={handleCloseTutorial}
        />
      </Block>
    </>
  );
};

export default JobberBooking;
