import { useState } from "react";
import { borderWidth, colors, radius, spacings } from "../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Icon,
  Link,
  List,
  Modal,
  Shape,
} from "../../components";
import { CardBase } from "../../components/Styles/Base";
import {
  BUTTON,
  LIST,
  LIST_HEADER,
  SHAPE,
} from "../../components/Styles/variants";
import { useSupportPhoneNumber } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import {
  SUPPORT_LINES,
  YOOJO_COVER_PRICE,
  YOOJO_EXTENDED_COVER_PRICE,
  formatPhone,
} from "../../utils";
import polyglot from "../../utils/polyglot";
import ExtendedCoverModal from "../jobber-booking/ExtendedCoverModal";

const HelpBoxContent = ({ title }) => {
  const { data, public_pages } = useJob();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { phone } = useSupportPhoneNumber(SUPPORT_LINES.CANCELLATION);
  return (
    <>
      {title && <List.Header withGutters>{title}</List.Header>}
      {data.cover && (
        <>
          <List.Header withGutters kind={LIST_HEADER.KIND.SUBTITLE}>
            {polyglot.t("common.help_cover_bloc")}
          </List.Header>
          <Block paddingX={spacings.m}>
            <List.Item
              withGutters
              divider={false}
              shape={LIST.SHAPE.ROUND}
              css={`
                border: solid ${borderWidth.s} ${colors.border};
              `}
              LeftComponent={() => (
                <>
                  {data.cover === "plus" ? (
                    <Shape.Medium
                      shape={SHAPE.SHAPE.CIRCLE}
                      backgroundColor={colors.primary}
                    >
                      <Icon.Large name="shield-plus-solid" />
                    </Shape.Medium>
                  ) : (
                    <Shape.Medium
                      shape={SHAPE.SHAPE.CIRCLE}
                      backgroundColor={colors.muted}
                    >
                      <Icon.Large name="shield-half" />
                    </Shape.Medium>
                  )}
                </>
              )}
            >
              <Body16>
                {data.cover === "plus"
                  ? polyglot.t("common.extended_cover")
                  : polyglot.t("common.cover")}
              </Body16>
              <Body14 color={colors.muted}>
                {polyglot.t("yoojo_cover.max_insurance_price_cover", {
                  amount: polyglot.toSmartCurrency(
                    data.cover === "plus"
                      ? YOOJO_EXTENDED_COVER_PRICE
                      : YOOJO_COVER_PRICE,
                    {
                      noDecimal: true,
                    }
                  ),
                })}
              </Body14>
              <Block marginTop={spacings.xs}>
                <Button.Link
                  onClick={() => setModalIsOpen(true)}
                  accentColor={BUTTON.ACCENT_COLOR.BODY}
                  css={`
                    padding: 0;
                    min-height: 0;
                  `}
                >
                  {polyglot.t("common.find_out_more")}
                </Button.Link>
              </Block>
            </List.Item>
            <ExtendedCoverModal
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              tab={data.cover === "plus" ? 0 : 1}
            />
          </Block>
        </>
      )}
      <List.Header
        withGutters
        kind={LIST_HEADER.KIND.SUBTITLE}
        RightComponent={() => (
          <Link href={public_pages.faq} target="_blank">
            {polyglot.t("common.see_more")}
          </Link>
        )}
      >
        {polyglot.t("common.frequent_questions")}
      </List.Header>
      <List.Group>
        {data.zendesk_articles?.map((article, i) => (
          <List.Item
            key={`referral-zendesk-article-${i}`}
            chevron
            withGutters
            href={article.url}
            target="_blank"
          >
            {article.title}
          </List.Item>
        ))}
      </List.Group>
      <List.Header withGutters kind={LIST_HEADER.KIND.SUBTITLE}>
        {polyglot.t("common.contact_us_alt")}
      </List.Header>
      <List.Group>
        <List.Item
          withGutters
          href={`${public_pages.contact}?from_job=${data.id}`}
          LeftComponent={() => (
            <Shape.Small
              backgroundColor={colors.primaryLight}
              color={colors.primary}
              shape={SHAPE.SHAPE.CIRCLE}
            >
              <Icon.Medium name="envelope" />
            </Shape.Small>
          )}
        >
          <Body16 strong color={colors.primary}>
            {polyglot.t("tickets.new")}
          </Body16>
        </List.Item>
        {data.show_phone_sav && (
          <List.Item
            withGutters
            href={`tel:${phone}`}
            LeftComponent={() => (
              <Shape.Small
                backgroundColor={colors.primaryLight}
                color={colors.primary}
                shape={SHAPE.SHAPE.CIRCLE}
              >
                <Icon.Medium name="phone" />
              </Shape.Small>
            )}
          >
            <Body16 color={colors.muted}>
              {polyglot.t("tickets.call_support")}
            </Body16>
            <Body16 strong color={colors.primary}>
              {formatPhone(phone)}
            </Body16>
            <Body14 color={colors.muted}>
              {polyglot.t("common.phone_assistance_schedule")}
            </Body14>
          </List.Item>
        )}
      </List.Group>
    </>
  );
};

const JobHelpBox = () => (
  <>
    <CardBase flat>
      <HelpBoxContent title={polyglot.t("common.need_help")} />
    </CardBase>
  </>
);

const HelpModal = ({ isOpen, onClose }) => (
  <Modal.FullScreen onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Title>{polyglot.t("common.need_help")}</Modal.Item.Title>
    </Modal.Item.Header>
    <HelpBoxContent />
  </Modal.FullScreen>
);

JobHelpBox.Modal = HelpModal;
export default JobHelpBox;
