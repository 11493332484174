import styled from "styled-components";
import { borderWidth, colors, radius, spacings } from "@/assets/themes";
import polyglot from "@/utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import { BANNER, BUTTON, TAG } from "../Styles/variants";
import Tag from "../Tag";
import { Body14, H5 } from "../Text";

const getColor = ({ accent }) => {
  if (accent === "light") return colors.body;
  return colors.onColor;
};

const getHeight = ({ size }) => {
  if (size === BANNER.SIZE.LARGE) return "200px";
  return "150px";
};

const getBodyFontSize = ({ size, bodyFontSize }) => {
  if (size === BANNER.SIZE.LARGE) return "var(--font-size-body18)";
  return bodyFontSize || "var(--font-size-body14)";
};

const getRightBackgroundImageSize = ({ size }) => {
  if (size === BANNER.SIZE.LARGE) return "200px";
  return "90px";
};

const getTitleFontSize = ({ size }) => {
  if (size === BANNER.SIZE.LARGE) return "var(--font-size-h4)";
  return "var(--font-size-h5)";
};

const getPadding = ({ size }) => {
  if (size === BANNER.SIZE.LARGE) return spacings.ml;
  return spacings.sm;
};

const getRadius = ({ size }) => {
  if (size === BANNER.SIZE.LARGE) return radius.l;
  return radius.ml;
};

const BannerCTA = ({ size, children }) => {
  if (size === BANNER.SIZE.LARGE) {
    return (
      <Button.Small
        size={BUTTON.SIZE.SMALL}
        kind={BUTTON.KIND.MINIMAL}
        shape={BUTTON.SHAPE.PILL}
        css={`
          pointer-events: none;
          color: ${colors.muted}!important;
        `}
        RightComponent={() => <Icon.Small name="arrow-right" />}
      >
        {children}
      </Button.Small>
    );
  }

  return (
    <Tag.Small
      css={`
        display: inline-flex;
        pointer-events: none;
      `}
      kind={TAG.KIND.WHITE}
      RightComponent={() => <Icon.Small name="arrow-right" />}
    >
      {polyglot.t("common.discover")}
    </Tag.Small>
  );
};
const StyledBannerWrapper = styled.div`
  height: ${({ size }) => getHeight({ size })};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "inherit"};
  background-size: cover;
  background-position: bottom right;
  border: solid ${borderWidth.s} ${colors.border};
  border-radius: ${({ size }) => getRadius({ size })};
  text-decoration: none;
  overflow: hidden;
  cursor: ${({ href, onClick }) => (href || onClick ? "pointer" : "auto")};
  &:hover {
    color: initial;
    text-decoration: none;
  }
`;

const Banner = ({
  backgroundColor,
  backgroundImage,
  title,
  subtitle,
  accent = "dark",
  onClose = null,
  href,
  target,
  onClick,
  rightBackgroundImage,
  rightBackgroundImageFit = "cover",
  rightBackgroundColor,
  bodyFontSize,
  renderButton,
  className,
  strong,
  buttonTitle = polyglot.t("common.discover"),
  size = BANNER.SIZE.MEDIUM,
}) => (
  <Block position="relative">
    {!!onClose && (
      <Block position="absolute" top="0px" right="0px">
        <Button.Small
          onClick={onClose}
          kind={BUTTON.KIND.MINIMAL}
          shape={BUTTON.SHAPE.CIRCLE}
          css={`
            background-color: transparent !important;
          `}
        >
          <Icon.Small name="times" color={getColor({ accent })} />
        </Button.Small>
      </Block>
    )}
    <StyledBannerWrapper
      as={href ? "a" : "div"}
      target={target}
      href={href}
      onClick={onClick}
      accent={accent}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      className={className}
      size={size}
    >
      <Block
        display="flex"
        flexDirection="column"
        justifyContent={href || onClick ? "space-between" : "center"}
        paddingY={getPadding({ size })}
        paddingLeft={getPadding({ size })}
        paddingRight={getPadding({ size })}
        height="100%"
      >
        <Block display="flex" flexDirection="column" gap={spacings.xs}>
          <H5
            color={getColor({ accent })}
            css={`
              font-size: ${getTitleFontSize({ size })};
            `}
          >
            {title}
          </H5>
          <Body14
            color={getColor({ accent })}
            strong={strong}
            css={`
              font-size: ${getBodyFontSize({ size, bodyFontSize })};
            `}
          >
            {subtitle}
          </Body14>
        </Block>
        {(href || onClick) && (
          <Block>
            {renderButton ? (
              renderButton()
            ) : (
              <BannerCTA size={size}>{buttonTitle}</BannerCTA>
            )}
          </Block>
        )}
      </Block>
      {rightBackgroundImage && (
        <div
          css={`
            background-color: ${rightBackgroundColor};
            max-width: ${getRightBackgroundImageSize({ size })};
            width: 100%;
            height: 100%;
          `}
        >
          <img
            alt={`${title} illustration`}
            src={rightBackgroundImage}
            css={`
              width: 100%;
              height: 100%;
              object-fit: ${rightBackgroundImageFit};
              object-position: right center;
            `}
          />
        </div>
      )}
    </StyledBannerWrapper>
  </Block>
);
export default Banner;
