import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { borderWidth, colors, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Badge,
  Block,
  Body14,
  Button,
  ConditionnalWrapper,
  H3,
  Icon,
  Link,
  Skeleton,
} from "@/components";
import { BUTTON, SKELETON } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import UserPreview from "@/pages-admin/UserPreview";
import { CUSTOMER } from "@/pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";
import {
  ACCOUNT_PATH,
  ACCOUNT_WALLET_PATH,
  NOTIFICATIONS_PATH,
} from "../config/routes";
import HomeAddressModal from "./HomeAddressModal";
import VipButton from "./VipButton";

const HomeHeader = ({ data }) => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const breakpoints = useBreakpoints();
  const history = useHistory();
  const badges = useSelector((state) => state.badges);
  const lastAddress = data?.addresses[0] || [];

  return (
    <Block display="flex" justifyContent="space-between" alignItems="center">
      <Block
        gap={{ xs: spacings.s, sm: spacings.sm }}
        display="flex"
        alignItems="center"
      >
        <div>
          <UserPreview id={data?.user_id} kind={CUSTOMER}>
            <VipButton
              vip_status={data?.vip_status}
              to={ACCOUNT_PATH}
              as={NavLink}
            >
              {data ? (
                <Avatar
                  size={breakpoints.get({
                    xs: sizes.size36,
                    md: sizes.size48,
                  })}
                  src={data?.avatar}
                  name={data?.first_name}
                />
              ) : (
                <Skeleton.Group>
                  <Skeleton
                    width={breakpoints.get({
                      xs: sizes.size36,
                      md: "44px",
                    })}
                    height={breakpoints.get({
                      xs: sizes.size36,
                      md: "44px",
                    })}
                    shape={SKELETON.SHAPE.ROUND}
                  />
                </Skeleton.Group>
              )}
            </VipButton>
          </UserPreview>
        </div>
        <Block>
          {data ? (
            <>
              <ConditionnalWrapper
                condition={data?.wallet_amount > 0}
                wrapper={(children) => (
                  <Link
                    to={ACCOUNT_WALLET_PATH}
                    color={colors.body}
                    css={`
                      text-decoration: none !important;
                    `}
                  >
                    {children}
                  </Link>
                )}
              >
                {/* css="" is needed to use the "as" props */}
                <span as={breakpoints.get({ xs: H3, md: H3 })} css="">
                  {data?.wallet_amount > 0
                    ? polyglot.toSmartCurrency(data?.wallet_amount)
                    : data?.first_name}
                </span>
              </ConditionnalWrapper>
              <Button.Link
                onClick={() => setAddressModalIsOpen(true)}
                css={`
                  margin-top: -4px;
                  padding-left: 0;
                  padding-right: 0;
                  color: ${lastAddress?.address ? colors.body : null}!important;
                  * {
                    text-decoration: none !important;
                  }
                `}
                LeftComponent={() => (
                  <Icon.Small name="map-marker" color={colors.muted} />
                )}
                RightComponent={() => (
                  <Icon.Small name="angle-down" color={colors.muted} />
                )}
              >
                <Body14 align="left" numberOfLines={1}>
                  {lastAddress?.address
                    ? lastAddress.address
                    : polyglot.t("common.add_address")}
                </Body14>
              </Button.Link>
              <HomeAddressModal
                initialValues={
                  lastAddress
                    ? { ...lastAddress, address_id: lastAddress.id }
                    : null
                }
                addresses={data?.addresses}
                isOpen={addressModalIsOpen}
                onClose={() => setAddressModalIsOpen(false)}
              />
            </>
          ) : (
            <Skeleton.Group>
              <Skeleton
                width={120}
                height={sizes.size24}
                shape={SKELETON.SHAPE.CIRCLE}
              />
              <Block marginTop={spacings.s}>
                <Skeleton
                  width={200}
                  height={sizes.size16}
                  shape={SKELETON.SHAPE.CIRCLE}
                />
              </Block>
            </Skeleton.Group>
          )}
        </Block>
      </Block>

      <Block gap={spacings.s} display="flex" alignItems="center">
        {breakpoints.get({
          xs: (
            <Button.Medium
              // key fix render ui problem
              key="discover-notification-button-xs"
              kind={BUTTON.KIND.MINIMAL}
              shape={BUTTON.SHAPE.CIRCLE}
              onClick={() => history.push(NOTIFICATIONS_PATH)}
            >
              <Badge count={badges?.notifications_count}>
                <Icon.Large name="bell" color={colors.body} />
              </Badge>
            </Button.Medium>
          ),
          md: (
            <Button.Small
              // key fix render ui problem
              key="discover-notification-button-md"
              kind={BUTTON.KIND.MINIMAL}
              shape={BUTTON.SHAPE.PILL}
              css={`
                border: solid ${borderWidth.m} ${colors.border};
              `}
              onClick={() => history.push(NOTIFICATIONS_PATH)}
              LeftComponent={() => <Icon.Medium name="bell" />}
              RightComponent={
                badges?.notifications_count > 0
                  ? () => <Badge count={badges?.notifications_count} />
                  : undefined
              }
            >
              {polyglot.t("routes.notifications")}
            </Button.Small>
          ),
        })}
      </Block>
    </Block>
  );
};

export default HomeHeader;
